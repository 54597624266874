import routerOptions0 from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/apps/phoenix/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}