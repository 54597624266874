import { graphql } from '~/graphql/generated/gql';

export const studentRoleFragment = graphql(`
  fragment StudentRoleFields on StudentRole {
    id
    firstName
    lastName
    orgType
    contextType
    orgId
    school {
      id
      name
      clusterId
    }
    classYear
    __typename
    createdAt
    state {
      id
      name
      code
    }
    isManageReferred
    isDemoRole
  }
`);

export const dashboardStudentRoleFragment = graphql(`
  fragment DashboardStudentRoleFields on StudentRole {
    id
    firstName
    lastName
    orgType
    contextType
    orgId
    classYear
    user {
      id
      primaryEmail
      # to get the group status
      userActivityGroups(activityGroupTypes: $activityGroupTypes) {
        id
        status
        activityGroup {
          id
          type
          name
        }
      }
      # to get the status of per activity item
      userActivities(statuses: $userActivityStatuses) {
        id
        status
        userActivityGroup {
          id
          activityGroup {
            id
            type
          }
        }
        activity {
          id
          title
        }
      }
    }
    isDemoRole
  }
`);

export const schoolStudentRoleInviteCountsFragment = graphql(`
  fragment SchoolStudentRoleInviteCountsFields on School {
    studentRoleRequestsCount
    studentRoleInvitesCount
  }
`);

export const teacherRoleFragment = graphql(`
  fragment TeacherRoleFields on TeacherRole {
    id
    firstName
    lastName
    orgType
    contextType
    roleType
    orgId
    school {
      id
      name
      clusterId
      stateId
      ...SchoolStudentRoleInviteCountsFields
    }
    state {
      id
      name
      code
    }
  }
`);

export const privateSiteConfigFragment = graphql(`
  fragment PrivateSiteConfigFields on SiteQueries {
    privateSiteConfig {
      orgTypesPermissions {
        type
        rolesPermissions {
          role
          permissions
        }
      }
    }
  }
`);

export const authUserFragment = graphql(`
  fragment AuthUserFields on User {
    id
    primaryEmail
    animationsEnabled
    isGhostStudent
    teacherRoles {
      ...TeacherRoleFields
    }
    schoolAdminRoles {
      ...TeacherRoleFields
    }
    studentRoles {
      ...StudentRoleFields
    }
    getPreferredTheme
    userActivityGroups {
      ...shallowUserActivityGroupFields
    }
  }
`);

export const userLoginFragment = graphql(`
  fragment UserLoginFields on UserLogin {
    id
    type
    value
    isPrimary
    verifiedAt
  }
`);

export const studentRoleRequestFragment = graphql(`
  fragment StudentRoleRequestFields on StudentRoleRequest {
    id
    firstName
    lastName
    email
    classYear
    status
    createdAt
  }
`);

export const schoolRequestFragment = graphql(`
  fragment SchoolRequestFields on StudentRoleRequest {
    id
    schoolId
    school {
      id
      name
      clusterId
    }
    classYear
    status
    __typename
    createdAt
  }
`);

export const studentRoleInviteFragment = graphql(`
  fragment StudentRoleInviteFields on StudentRoleInvite {
    id
    schoolId
    school {
      id
      name
      clusterId
    }
    classYear
    status
    __typename
    createdAt
  }
`);

export const schoolStudentRoleInviteFragment = graphql(`
  fragment SchoolStudentRoleInviteFields on StudentRoleInvite {
    id
    firstName
    lastName
    email
    classYear
    status
    createdAt
  }
`);

export const schoolManagementDataFragment = graphql(`
  fragment SchoolManagementUserFields on User {
    id
    studentRoles {
      ...StudentRoleFields
    }
    studentRoleRequests {
      ...SchoolRequestFields
    }
    studentRoleInvites {
      ...StudentRoleInviteFields
    }
  }
`);

export const paginatorInfoFragment = graphql(`
  fragment PaginatorInfoFields on PaginatorInfo {
    currentPage
    lastPage
    total
  }
`);

export const coreSiteConfig = graphql(`
  query coreSiteConfig($domain: String!) {
    core {
      site {
        publicSiteConfig {
          country {
            id
            name
            code
            defaultLocale
            rolloverDay
            rolloverMonth
            firstYearGroup
            lastYearGroup
            displayAsGraduatedStartYearGroup
            currentClassYearOptions {
              grade
              year
            }
            allClassYearOptions {
              grade
              year
            }
          }
          sessionLifetime
        }
        i18nMessages
        acceptedFileTypes {
          value {
            type
            mimes
            extensions
          }
        }
      }
      org {
        cluster(domain: $domain) {
          id
          name
          code
          domain
          planProductName
          manageProductName
          theme {
            colors {
              id
              value
            }
            logos {
              iconmarkLight
              iconmarkDark
              iconmarkMixed
              lockupPrimaryLight
              lockupPrimaryDark
            }
          }
          allowOtherSchoolsInRegistration
          hasAgeConsentCheck
        }
      }
    }
  }
`);

export const requestLoginCodeMutation = graphql(`
  mutation requestLoginCode($input: RequestLoginCodeInput!) {
    core {
      auth {
        requestLoginCode(input: $input) {
          success
        }
      }
    }
  }
`);

export const verifyLoginCodeMutation = graphql(`
  mutation login($input: VerifyLoginCodeInput!) {
    core {
      auth {
        verifyLoginCode(input: $input) {
          flow
        }
      }
    }
  }
`);

export const logoutMutation = graphql(`
  mutation logout {
    core {
      auth {
        logout {
          success
        }
      }
    }
  }
`);

export const signUpStudentMutation = graphql(`
  mutation signUpStudent($input: SignUpStudentInput!) {
    core {
      user {
        signUpStudent(input: $input) {
          success
        }
      }
    }
  }
`);
export const createGhostStudentMutation = graphql(`
  mutation createGhostStudent($input: CreateGhostStudentInput!) {
    core {
      user {
        createGhostStudent(input: $input) {
          success
        }
      }
    }
  }
`);

export const signUpTeacherMutation = graphql(`
  mutation signUpTeacher($input: SignUpTeacherInput!) {
    core {
      user {
        signUpTeacher(input: $input) {
          success
        }
      }
    }
  }
`);

export const selfQuery = graphql(`
  query self {
    core {
      user {
        self {
          ...AuthUserFields
        }
      }
      site {
        ...PrivateSiteConfigFields
      }
    }
  }
`);

export const clusterSchoolsQuery = graphql(`
  query clusterSchools($clusterId: String!, $schoolFilters: SchoolFilters) {
    core {
      org {
        cluster(id: $clusterId) {
          id
          schools(filters: $schoolFilters, page: 1, first: 50) {
            data {
              id
              name
              label
            }
          }
        }
      }
    }
  }
`);

export const getSchoolStudentRoles = graphql(`
  query getSchoolStudentRoles(
    $page: Int!
    $schoolId: ID!
    $filters: StudentRoleFilters
    $orderBy: [SchoolStudentRolesOrderByOrderByClause!]
    $activityGroupTypes: [ActivityGroupType!]
    $userActivityStatuses: [ActivityStatus!]
  ) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            studentRoles(page: $page, filters: $filters, orderBy: $orderBy) {
              data {
                ...DashboardStudentRoleFields
              }
              paginatorInfo {
                ...PaginatorInfoFields
              }
            }
          }
        }
      }
    }
  }
`);

export const getSchoolStudentRoleRequestsQuery = graphql(`
  query getSchoolStudentsRoleRequests(
    $page: Int!
    $schoolId: ID!
    $filters: StudentRoleRequestFilters
    $orderBy: [SchoolStudentRoleRequestsOrderByOrderByClause!]
  ) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            ...SchoolStudentRoleInviteCountsFields
            studentRoleRequests(page: $page, filters: $filters, orderBy: $orderBy) {
              data {
                ...StudentRoleRequestFields
              }
              paginatorInfo {
                ...PaginatorInfoFields
              }
            }
          }
        }
      }
    }
  }
`);

export const approveStudentRoleRequestMutation = graphql(`
  mutation approveStudentRoleRequest($input: StudentRoleRequestInput!) {
    core {
      org {
        school {
          approveStudentRoleRequest(input: $input) {
            request {
              ...StudentRoleRequestFields
            }
          }
        }
      }
    }
  }
`);

export const declineStudentRoleRequestMutation = graphql(`
  mutation declineStudentRoleRequest($input: StudentRoleRequestInput!) {
    core {
      org {
        school {
          declineStudentRoleRequest(input: $input) {
            request {
              ...StudentRoleRequestFields
            }
          }
        }
      }
    }
  }
`);

export const findStudentQuery = graphql(`
  query getUser($schoolId: ID!, $filters: StudentRoleFilters) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            studentRoles(filters: $filters, first: 1, page: 1) {
              data {
                ...StudentRoleFields
                user {
                  ...AuthUserFields
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const updateStudentRoleMutation = graphql(`
  mutation updateStudentRole($input: UpdateStudentRoleInput!) {
    core {
      org {
        school {
          updateStudentRole(input: $input) {
            studentRole {
              ...StudentRoleFields
            }
          }
        }
      }
    }
  }
`);

export const updateTeacherRoleMutation = graphql(`
  mutation updateTeacherRole($input: UpdateTeacherRoleInput!) {
    core {
      org {
        school {
          updateTeacherRole(input: $input) {
            teacherRole {
              ...TeacherRoleFields
            }
          }
        }
      }
    }
  }
`);

export const deleteSelfMutation = graphql(`
  mutation deleteSelf {
    core {
      user {
        deleteSelf {
          success
        }
      }
    }
  }
`);

export const fileFragment = graphql(`
  fragment FileFields on File {
    id
    userId
    type
    path
    originalName
    signedUrl
    size
    mimeType
  }
`);

export const uploadFile = graphql(`
  mutation uploadFile($input: [UploadInput!]!) {
    core {
      file {
        upload(input: $input) {
          data {
            ...FileFields
          }
        }
      }
    }
  }
`);

export const getSelfUploadedFiles = graphql(`
  query getSelfUploadedFiles($filters: FileFilters) {
    core {
      user {
        self {
          id
          files(filters: $filters) {
            ...FileFields
          }
        }
      }
    }
  }
`);

export const inviteStudentsMutation = graphql(`
  mutation inviteStudents($input: InviteStudentsInput!, $schoolId: ID!) {
    core {
      org {
        school {
          inviteStudents(input: $input) {
            success
            query {
              core {
                org {
                  school {
                    school(id: $schoolId) {
                      id
                      studentRoleInvitesCount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const checkStudentRoleInvite = graphql(`
  query checkStudentRoleInvite($input: CheckStudentRoleInviteInput!) {
    core {
      org {
        school {
          checkStudentRoleInvite(input: $input) {
            isValid
            school {
              id
              name
            }
            email
          }
        }
      }
    }
  }
`);

export const declineStudentRoleInvite = graphql(`
  mutation declineStudentRoleInvite($input: DeclineStudentRoleInviteInput!) {
    core {
      org {
        school {
          declineStudentRoleInvite(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const requestStudentInviteOtpCodeMutation = graphql(`
  mutation requestStudentInviteOtpCode($input: RequestStudentInviteOtpCodeInput!) {
    core {
      org {
        school {
          requestStudentInviteOtpCode(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const verifyStudentInviteOtpCodeMutation = graphql(`
  mutation verifyStudentInviteOtpCode($input: VerifyStudentInviteOtpCodeInput!) {
    core {
      org {
        school {
          verifyStudentInviteOtpCode(input: $input) {
            flow
          }
        }
      }
    }
  }
`);

export const setAnimationsEnabled = graphql(`
  mutation setAnimationsEnabled($animationsEnabled: Boolean!) {
    core {
      user {
        setAnimationsEnabled(animationsEnabled: $animationsEnabled) {
          animationsEnabled
        }
      }
    }
  }
`);

export const getSchoolRequestsAndInvites = graphql(`
  query getSchoolRequestsAndInvites {
    core {
      user {
        self {
          ...SchoolManagementUserFields
        }
      }
    }
  }
`);

export const respondStudentRoleInvite = graphql(`
  mutation respondStudentRoleInvite($input: RespondStudentRoleInviteInput!) {
    core {
      org {
        school {
          respondStudentRoleInvite(input: $input) {
            success
            user {
              ...SchoolManagementUserFields
            }
          }
        }
      }
    }
  }
`);

export const createSchoolRequest = graphql(`
  mutation createSchoolRequest($input: CreateStudentRoleRequestInput!) {
    core {
      org {
        school {
          createStudentRoleRequest(input: $input) {
            data {
              ...SchoolRequestFields
            }
            user {
              ...SchoolManagementUserFields
            }
          }
        }
      }
    }
  }
`);

export const deleteRole = graphql(`
  mutation deleteRole($input: DeleteRoleInput!) {
    core {
      org {
        school {
          deleteRole(input: $input) {
            success
            user {
              ...SchoolManagementUserFields
            }
          }
        }
      }
    }
  }
`);

export const bulkDeleteStudentRolesMutation = graphql(`
  mutation bulkDeleteStudentRoles($input: BulkActionStudentRolesInput!) {
    core {
      org {
        school {
          bulkDeleteStudentRoles(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const deleteSchoolRequest = graphql(`
  mutation deleteSchoolRequest($input: StudentRoleRequestInput!) {
    core {
      org {
        school {
          deleteStudentRoleRequest(input: $input) {
            success
            user {
              ...SchoolManagementUserFields
            }
          }
        }
      }
    }
  }
`);

export const getSiteCountry = graphql(`
  query getSiteCountry {
    core {
      site {
        publicSiteConfig {
          country {
            id
            name
            code
            states {
              id
              name
              code
            }
          }
        }
      }
    }
  }
`);

export const bulkRespondStudentRoleRequestsMutation = graphql(`
  mutation bulkRespondStudentRoleRequests($input: BulkRespondStudentRoleRequestsInput!) {
    core {
      org {
        school {
          bulkRespondStudentRoleRequests(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const getSchoolStudentInvitesQuery = graphql(`
  query getSchoolStudentInvites(
    $page: Int!
    $schoolId: ID!
    $filters: StudentRoleInviteFilters
    $orderBy: [SchoolStudentRoleInvitesOrderByOrderByClause!]
  ) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            ...SchoolStudentRoleInviteCountsFields
            studentRoleInvites(page: $page, filters: $filters, orderBy: $orderBy) {
              data {
                ...SchoolStudentRoleInviteFields
              }
              paginatorInfo {
                ...PaginatorInfoFields
              }
            }
          }
        }
      }
    }
  }
`);

export const deleteStudentRoleInviteMutation = graphql(`
  mutation deleteStudentRoleInvite($input: StudentRoleInviteInput!) {
    core {
      org {
        school {
          deleteStudentRoleInvite(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const resendStudentRoleInviteMutation = graphql(`
  mutation resendStudentRoleInvite($input: StudentRoleInviteInput!) {
    core {
      org {
        school {
          resendStudentRoleInvite(input: $input) {
            success
            studentRoleInvite {
              ...SchoolStudentRoleInviteFields
            }
          }
        }
      }
    }
  }
`);

export const bulkDeleteStudentRoleInvitesMutation = graphql(`
  mutation bulkDeleteStudentRoleInvites($input: BulkActionStudentRoleInvitesInput!) {
    core {
      org {
        school {
          bulkDeleteStudentRoleInvites(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const bulkResendStudentRoleInvitesMutation = graphql(`
  mutation bulkResendStudentRoleInvites($input: BulkActionStudentRoleInvitesInput!) {
    core {
      org {
        school {
          bulkResendStudentRoleInvites(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const studentRoleInviteUpdatedSubscription = graphql(`
  subscription studentRoleInviteUpdated($schoolId: ID!) {
    studentRoleInviteUpdated(schoolId: $schoolId) {
      ...SchoolStudentRoleInviteFields
    }
  }
`);

export const studentRoleRequestCreatedSubscription = graphql(`
  subscription studentRoleRequestCreated($schoolId: ID!) {
    studentRoleRequestCreated(schoolId: $schoolId) {
      id
      school {
        id
        studentRoleRequestsCount
      }
      deletedAt
    }
  }
`);

export const getUserEmailLogins = graphql(`
  query getUserEmailLogins {
    core {
      user {
        self {
          id
          emails {
            ...UserLoginFields
          }
        }
      }
    }
  }
`);

export const addSecondaryEmailLoginMutation = graphql(`
  mutation addSecondaryEmailLogin($input: AddSecondaryEmailUserLoginInput!) {
    core {
      user {
        addSecondaryEmailLogin(input: $input) {
          success
          query {
            core {
              user {
                self {
                  id
                  emails {
                    ...UserLoginFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const deleteSecondaryEmailLoginMutation = graphql(`
  mutation deleteSecondaryEmailLogin($input: DeleteSecondaryEmailUserLoginInput!) {
    core {
      user {
        deleteSecondaryEmailLogin(input: $input) {
          success
          query {
            core {
              user {
                self {
                  id
                  emails {
                    ...UserLoginFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const verifyEmailVerificationCodeMutation = graphql(`
  mutation verifyEmailVerificationCode($input: VerifyEmailVerificationCodeInput!) {
    core {
      user {
        verifyEmailVerificationCode(input: $input) {
          success
          query {
            core {
              user {
                self {
                  id
                  emails {
                    ...UserLoginFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const resendEmailVerificationMutation = graphql(`
  mutation resendEmailVerificationCode($input: ResendEmailVerificationCodeInput!) {
    core {
      user {
        resendEmailVerificationCode(input: $input) {
          success
          query {
            core {
              user {
                self {
                  id
                  emails {
                    ...UserLoginFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const getStudentWallet = graphql(`
  query getStudentWallet($schoolId: ID!, $filters: StudentRoleFilters) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            studentRoles(filters: $filters, first: 1, page: 1) {
              data {
                ...StudentRoleFields
                user {
                  id
                  wallet {
                    achievementSummary {
                      reflectAchievementSummary
                    }
                    achievements {
                      achievementTitle
                      achievementDate
                      achievementSupportingDoc {
                        ...FileFields
                      }
                      achievementOrganisation
                      achievementSkills
                    }
                    addressDetails {
                      addressState
                      addressPostcode
                    }
                    education {
                      institutionDetails {
                        institutionType
                        institutionDetailsStatus
                        institutionDetailsName
                        institutionDetailsState
                        institutionDetailsDateStart
                        institutionDetailsDateEnd
                      }
                    }
                    educationDetails {
                      schoolName
                      yearGroup
                    }
                    educationSummary {
                      reflectEducationSummary
                    }
                    evidence {
                      evidenceDetailsTitle
                      evidenceDetailsDate
                      evidenceType
                      evidenceSupportingDoc {
                        ...FileFields
                      }
                      evidenceSupportingLink
                    }
                    myBioComposite {
                      myBio
                    }
                    myDetails {
                      firstName
                      lastName
                    }
                    transcriptReport {
                      institutionQualificationName
                      institutionQualificationDocument {
                        ...FileFields
                      }
                    }
                    workExperience {
                      workplaceDetailsRole
                      workplaceDetailsName
                      workplaceDetailsDateStart
                      workplaceDetailsDateEnd
                      workplaceDetailsDescription
                      workplaceDetailsCompanyLogo {
                        ...FileFields
                      }
                    }
                    workExperienceSummary {
                      reflectWorkExperienceSummary
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const bulkExportCareerReadinessReports = graphql(`
  mutation bulkExportCareerReadinessReports($input: BulkActionStudentRolesInput!) {
    core {
      org {
        school {
          bulkExportCareerReadinessReports(input: $input) {
            success
          }
        }
      }
    }
  }
`);

export const addTeacherRoleToStudentMutation = graphql(`
  mutation addTeacherRoleToStudent {
    core {
      user {
        addTeacherRoleToStudent {
          success
          query {
            core {
              user {
                self {
                  ...AuthUserFields
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const addStudentRoleToTeacherMutation = graphql(`
  mutation addStudentRoleToTeacher {
    core {
      user {
        addStudentRoleToTeacher {
          success
          query {
            core {
              user {
                self {
                  ...AuthUserFields
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const coreActivityGroupFragment = graphql(`
  fragment CoreActivityGroupFields on ActivityGroup {
    id
    type
    name
    activities {
      id
      title
    }
  }
`);

export const getActivityGroups = graphql(`
  query getActivityGroups($types: [ActivityGroupType!]) {
    activities {
      activityGroups(types: $types) {
        ...CoreActivityGroupFields
      }
    }
  }
`);
