export const useFeatureFlags = () => {
  const { $featureFlagsProvider } = useNuxtApp();

  const { user, authId, schoolOperationalRoles, schoolStudentRoles, globalTeacherRole, globalStudentRole } = useAuth();

  const configStore = useConfigStore();

  const { isManageDomain } = useDomainConfig();

  const schoolIds = computed(() => {
    if (!user.value) {
      return [];
    }

    if (isManageDomain) {
      return schoolOperationalRoles.value.map((r) => r.orgId);
    }

    return schoolStudentRoles.value.map((r) => r.orgId);
  });

  const stateCode = computed(() => {
    if (!user.value) {
      return [];
    }

    if (isManageDomain) {
      return globalTeacherRole.value?.state?.code;
    }

    return globalStudentRole.value?.state?.code;
  });

  const userContext = computed(() => {
    return {
      userId: authId.value,
      schoolsIds: schoolIds.value,
      stateCode: stateCode.value || 'UNKNOWN',
      clusterCode: configStore.cluster?.code || 'UNKNOWN',
    };
  });

  const getFlag = async (flagName: string) => await $featureFlagsProvider.getFlag(flagName, userContext.value);

  const getVariant = async (flagName: string) => await $featureFlagsProvider.getVariant(flagName, userContext.value);

  const getEvaluatedFlags = () => $featureFlagsProvider.getEvaluatedFlags();

  return {
    getFlag,
    getVariant,
    getEvaluatedFlags,
  };
};
