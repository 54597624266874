/* eslint-disable no-console */
import type { RuntimeConfig } from 'nuxt/schema';
import type { FeatureFlags, FeatureFlagsProvider, UserContext, Variant } from '~/types/featureFlagsProvider';
import { UnleashClient } from '@unleash/proxy-client-vue';

interface UnleashImpressionEvent {
  eventType: 'isEnabled' | 'getVariant';
  enabled: boolean;
  featureName: string;
  variant?: string;
}

type ImpressionCallback = (event: { enabled: boolean; name: string; type: 'flag' | 'variant'; variant?: string }) => void;

export default class ClientSideProvider implements FeatureFlagsProvider {
  private client: UnleashClient;
  private userContext?: UserContext;

  evaluatedFlags: Map<string, boolean | Variant>;

  constructor(runtimeConfig: RuntimeConfig) {
    this.client = new UnleashClient({
      url: `${runtimeConfig.public.unleashFlagsUrl}/frontend`,
      clientKey: runtimeConfig.public.unleashFlagsClientToken,
      appName: runtimeConfig.public.appName,
      refreshInterval: 30,
    });
    this.evaluatedFlags = new Map();
  }

  async init(): Promise<void> {
    return await new Promise((resolve, reject) => {
      this.client.on('ready', () => {
        this.attachImpressionEventCallback((event) => {
          // Add to evaluated flags during this client session
          this.evaluatedFlags.set(
            event.name,
            event?.variant
              ? {
                  name: event.variant,
                  enabled: event.enabled,
                }
              : event.enabled,
          );

          // Also track specific impression event
          useAnalyticsService().track(EVENTS.FEATURE_FLAG_IMPRESSION, {
            featureFlagImpression: JSON.stringify(event),
          });
        });
        resolve();
      });
      this.client.on('error', (error: unknown) => {
        console.error('unleash:on-error', error);
        this.destroy();
        reject(error);
      });
      void this.client.start();
    });
  }

  async getFlag(flagName: string, userContext: UserContext): Promise<boolean> {
    await this.syncUserContext(userContext);
    return this.client.isEnabled(flagName);
  }

  async getVariant(flagName: string, userContext: UserContext): Promise<Variant> {
    await this.syncUserContext(userContext);
    return this.client.getVariant(flagName);
  }

  getEvaluatedFlags(): FeatureFlags {
    return Object.fromEntries(this.evaluatedFlags);
  }

  private attachImpressionEventCallback(callback: ImpressionCallback): void {
    this.client.on('impression', (e: UnleashImpressionEvent) =>
      callback({
        type: e.eventType === 'isEnabled' ? 'flag' : 'variant',
        name: e.featureName,
        enabled: e.enabled,
        variant: e.variant,
      }),
    );
  }

  private async syncUserContext(userContext: UserContext): Promise<void> {
    if (!userContext || JSON.stringify(userContext) !== JSON.stringify(this.userContext)) {
      this.userContext = userContext;
      await this.client.updateContext(this.userContext);
    }
  }

  destroy(): void {
    this.client.stop();
  }
}
