import { useSubscription, type SubscriptionHandler } from '@urql/vue';
import { studentRoleRequestCreatedSubscription, schoolStudentRoleInviteCountsFragment } from '~/graphql/documents/core';
import type { StudentRoleRequestCreatedSubscription } from '~/graphql/generated/graphql';
import { useFragment } from '~/graphql/generated/fragment-masking';

export const useStudentRequest = () => {
  const { currentSchoolId, canManageStudents } = useAuth();

  const newStudentRequestId = ref<string | undefined>(undefined);
  const handleSubscription: SubscriptionHandler<StudentRoleRequestCreatedSubscription, never[] | undefined> = (messages, response) => {
    if (!response.studentRoleRequestCreated?.deletedAt) {
      newStudentRequestId.value = response.studentRoleRequestCreated?.id;
    }
    return messages;
  };

  useSubscription(
    {
      query: studentRoleRequestCreatedSubscription,
      variables: computed(() => ({ schoolId: currentSchoolId.value })),
      pause: computed(() => !currentSchoolId.value || !canManageStudents.value),
    },
    handleSubscription,
  );

  const clearNewStudentRequest = () => {
    newStudentRequestId.value = undefined;
  };

  return { newStudentRequestId, clearNewStudentRequest };
};

export const useSchoolInviteCount = () => {
  const { currentSchoolId, schoolOperationalRoles } = useAuth();

  const currentSchool = computed(() => schoolOperationalRoles.value.find((r) => r.orgId === currentSchoolId.value)?.school);
  const _data = computed(() => {
    if (!currentSchool.value) {
      return undefined;
    }

    return useFragment(schoolStudentRoleInviteCountsFragment, currentSchool.value);
  });

  const schoolRequestsCount = computed(() => _data.value?.studentRoleRequestsCount || 0);
  const schoolInvitesCount = computed(() => _data.value?.studentRoleInvitesCount || 0);
  return { schoolRequestsCount, schoolInvitesCount };
};
