<script lang="ts" setup>
import type { HeadingLevels } from "~ui/types/ui";
interface Props {
  size: HeadingLevels;
  styleSize?: HeadingLevels;
  isHidden?: boolean;
  customClasses?: string;
  htmlText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isHidden: false,
  styleSize: "h1",
  customClasses: "",
  htmlText: "",
});

const variantClasses = computed<string>(() => {
  return twMerge(
    cva("leading-tight font-semibold", {
      variants: {
        styleSize: {
          h1: "text-4xl lg:text-5xl",
          h2: "text-3xl lg:text-4xl",
          h3: "text-2xl lg:text-3xl",
          h4: "text-xl lg:text-2xl",
          h5: "text-xl",
          h6: "text-lg",
        },
        isHidden: {
          true: "sr-only",
        },
      },
    })({
      styleSize: props.styleSize,
      isHidden: props.isHidden,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <component :is="size" :class="variantClasses">
    <!-- eslint-disable-next-line -->
    <div v-if="htmlText" v-html="htmlText" />
    <slot v-else />
  </component>
</template>
