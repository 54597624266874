import type { RuntimeConfig } from 'nuxt/schema';
import type { AnalyticsProvider } from '~analytics/types/analyticsProvider';
import mixpanel from 'mixpanel-browser';

export default class MixpanelProvider implements AnalyticsProvider {
  serviceName: string;
  subscribedEvents: string[];
  config: RuntimeConfig;

  constructor(serviceName: string, config: RuntimeConfig, subscribedEvents: string[]) {
    this.serviceName = serviceName;
    this.subscribedEvents = subscribedEvents;
    this.config = config;
  }

  init() {
    if (!this.config.public.mixpanelToken) {
      console.warn('MixpanelProvider initialized without public mixpanelToken');
    }
    mixpanel.init(this.config.public.mixpanelToken);
  }
  track(eventName: string, eventData?: object) {
    mixpanel.track(eventName, eventData);
  }

  identify(guid: string | undefined) {
    mixpanel.identify(guid);
  }

  getSubscribedEvents() {
    return this.subscribedEvents;
  }
}
