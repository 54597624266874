import { authUserFragment, selfQuery } from '~/graphql/documents/core';
import { type FragmentType, useFragment } from '~/graphql/generated/fragment-masking';

type UserType = FragmentType<typeof authUserFragment>;

export const useAuthStore = defineStore('auth', () => {
  const { clusterDomain } = useDomainConfig();

  const authCookie = useCookie('auth', { domain: `.${clusterDomain}`, sameSite: 'none', path: '/', secure: true });
  const currentSchoolId = useCookie('current-school-id');
  const guestId = useCookie('guest-id', { default: () => crypto.randomUUID() });
  const configStore = useConfigStore();

  const { $urqlClient } = useNuxtApp();

  const isloggingOut = ref(false);

  const _user: Ref<UserType | null> = ref(null);

  const user = computed(() => {
    return useFragment(authUserFragment, _user.value);
  });

  const setUser = (newUser: UserType | null) => {
    _user.value = newUser;
  };

  const isLoggedIn = computed(() => {
    return !!user.value;
  });

  const fetchUser = async (networkOnly = false) => {
    const res = await $urqlClient.query(selfQuery, {}, { requestPolicy: networkOnly ? 'network-only' : 'cache-first' });

    if (res.data?.core && !res.error) {
      if (res.data.core.user.self) {
        setUser(res.data.core.user.self);
        authCookie.value = 'authenticated';
      }

      if (res.data.core.site) {
        configStore.setPrivateSiteConfig(res.data.core.site);
      }
      return;
    }

    authCookie.value = undefined;

    if (!res.error) {
      return;
    }

    const unauthenticatedError = findGraphqlErrorWithCode(res.error, 'UNAUTHENTICATED');

    if (unauthenticatedError) {
      return;
    }

    throw createError({
      statusCode: 500,
      statusMessage: 'Could not fetch user',
      data: res.error,
      fatal: true,
    });
  };

  const checkForAuthUser = async () => {
    if (authCookie.value === 'authenticated') {
      await fetchUser();
    }
  };

  const unsubscribe: Ref<null | (() => void)> = ref(null);

  if (import.meta.client) {
    watchEffect(() => {
      if (user.value) {
        if (!unsubscribe.value) {
          unsubscribe.value = $urqlClient.query(selfQuery, {}).subscribe((res) => {
            setUser(res.data?.core.user.self || null);
          }).unsubscribe;
        }
      } else {
        if (unsubscribe.value) {
          unsubscribe.value();
          unsubscribe.value = null;
        }
      }
    });
  }

  const reset = () => {
    setUser(null);
    authCookie.value = undefined;
    currentSchoolId.value = undefined;
  };

  const sessionTimeout = (configStore.publicConfig?.sessionLifetime ?? Infinity) * 60 * 1000;

  const expiry = ref<number>(Date.now() + sessionTimeout);

  const refreshExpiry = () => {
    expiry.value = Date.now() + sessionTimeout;
  };

  const authId = computed(() => user.value?.id || guestId.value);
  const hasAuthedCookie = computed(() => authCookie.value === 'authenticated');

  return {
    user,
    setUser,
    isLoggedIn,
    isloggingOut,
    authCookie,
    guestId,
    checkForAuthUser,
    reset,
    currentSchoolId,
    fetchUser,
    expiry,
    refreshExpiry,
    authId,
    hasAuthedCookie,
  };
});
