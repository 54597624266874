import { type Permission } from '~/graphql/generated/graphql';
import { routeConfigError } from '~/utils/errors/routeConfigError';

export default defineNuxtRouteMiddleware(async (to) => {
  const { requiredRolePermissions, authType } = to.meta;

  if (requiredRolePermissions && !Array.isArray(requiredRolePermissions)) {
    throw routeConfigError(`Route ${to.path} has invalid requiredRolePermissions configuration. Expected array.`);
  } else if (authType && !Array.isArray(authType)) {
    throw routeConfigError(`Route ${to.path} has invalid authType configuration. Expected array.`);
  }

  const _requiredRolePermissions = <Permission[]>requiredRolePermissions || [];
  const { hasPermissions } = useAuth();
  const { isLoggedIn, user } = useAuthStore();
  const { $i18n } = useNuxtApp();

  const loginRedirectRoute = '/login';
  const dashboardRedirectPath = '/dashboard';

  // if there is no indication of a private site, return true
  if (!authType?.some((type) => type === AUTH_ROUTE_TYPE_AUTHENTICATED)) {
    return true;
  }

  if (!isLoggedIn || !user) {
    return await navigateTo(loginRedirectRoute, { replace: true });
  }

  if (_requiredRolePermissions.length === 0) {
    return true;
  }

  if (!hasPermissions(_requiredRolePermissions)) {
    if (to.path === dashboardRedirectPath) {
      // if a user has no basic permissions, and redirected to dashboard, show a 403 error instead
      return showError({ statusCode: 403, statusMessage: $i18n.t('general.errors.forbidden') });
    }

    return await navigateTo(dashboardRedirectPath, { replace: true });
  }

  return true;
});
