import type { RuntimeConfig } from 'nuxt/schema';
import type { AnalyticsProvider } from '~analytics/types/analyticsProvider';

interface GoogleAnalytics {
  gtag: (operationType: string, eventName: string, eventData?: object) => void;
}
export default class GoogleAnalyticsProvider implements AnalyticsProvider {
  serviceName: string;
  subscribedEvents: string[];
  googleAnalytics?: GoogleAnalytics;

  constructor(serviceName: string, config: RuntimeConfig, subscribedEvents: string[]) {
    this.serviceName = serviceName;
    this.subscribedEvents = subscribedEvents;
  }
  async init() {
    this.googleAnalytics = <GoogleAnalytics>await useScriptGoogleAnalytics();
  }
  track(eventName: string, eventData?: object) {
    this.googleAnalytics?.gtag('event', eventName, eventData);
  }

  identify() {
    // noop atm
  }

  getSubscribedEvents() {
    return this.subscribedEvents;
  }
}
