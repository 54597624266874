import { routeConfigError } from '~/utils/errors/routeConfigError';

export default defineNuxtRouteMiddleware(async (to) => {
  // Get the authType from meta
  const { authType } = to.meta;

  // Check if authType exists and is an array
  if (authType && !Array.isArray(authType)) {
    throw routeConfigError(`Route ${to.path} has invalid authType configuration. Expected array.`);
  }

  const routeAuthType = authType || [AUTH_ROUTE_TYPE_AUTHENTICATED];

  const { hasPlatformRole, userHasNoTeacherRole } = useAuth();
  const { isLoggedIn, user } = useAuthStore();

  const loginRedirectRoute = '/login';
  const dashboardRedirectPath = '/dashboard';

  const guards = {
    [AUTH_ROUTE_TYPE_NONE]: {
      check: () => true,
      // eslint-disable-next-line no-empty-function
      failedRedirect: () => {},
    },
    [AUTH_ROUTE_TYPE_AUTHENTICATED]: {
      check: () => {
        return hasPlatformRole();
      },
      failedRedirect: async () => {
        return await navigateTo(loginRedirectRoute, { replace: true });
      },
    },
    [AUTH_ROUTE_TYPE_PLAN_GUEST]: {
      check: () => {
        return !isLoggedIn;
      },
      failedRedirect: async () => {
        return await navigateTo(dashboardRedirectPath, { replace: true });
      },
    },
    [AUTH_ROUTE_TYPE_MANAGE_GUEST]: {
      check: () => {
        return !isLoggedIn || userHasNoTeacherRole.value;
      },
      failedRedirect: async () => {
        return await navigateTo(dashboardRedirectPath, { replace: true });
      },
    },
    [AUTH_ROUTE_TYPE_GHOST]: {
      //TODO: implement isGhostTeacher based on siteType when needed
      check: () => {
        return isLoggedIn && user?.isGhostStudent;
      },
      failedRedirect: async () => {
        return await navigateTo('/', { replace: true });
      },
    },
  };

  const passesAnyCheck = routeAuthType.some((type) => {
    const guard = guards[type];
    return guard && guard.check();
  });

  if (!passesAnyCheck) {
    const firstGuard = guards[routeAuthType[0]];
    if (firstGuard) {
      return await firstGuard.failedRedirect();
    }
  }
});
