import { type AnalyticsProvider, type AnalyticsServiceProvider } from '~analytics/types/analyticsProvider';

export const useAnalyticsServiceProvider = (baseEventData?: () => object): AnalyticsServiceProvider => {
    const providers = ref<AnalyticsProvider[]>([]);

    const defaultEventData = () => ({})
    const getBaseEventData = baseEventData || defaultEventData;

    const track = (eventName: string, specificEventData: object = {}) => {
        if (!import.meta.client) {
            return;
        }

        const eventData = { ...getBaseEventData(), ...specificEventData };

        providers.value.forEach((provider) => {
            if (provider.getSubscribedEvents().includes(eventName)) {
                try {
                    provider.track(eventName, eventData);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        });
    };

    const identify = (guid: string | undefined) => {
        if (!import.meta.client) {
            return;
        }

        providers.value.forEach((provider) => {
            try {
                provider.identify(guid);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        });
    };

    const registerProvider = async (provider: AnalyticsProvider) => {
        await provider.init();
        providers.value.push(provider);
    };

    return { track, identify, registerProvider, getBaseEventData };
};
