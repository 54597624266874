import validate from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45auth_45route_45protection_45global from "/var/www/apps/phoenix/nuxt/middleware/00.auth-route-protection.global.ts";
import _01_45autoload_45global from "/var/www/apps/phoenix/nuxt/middleware/01.autoload.global.ts";
import _02_45permission_45route_45protection_45global from "/var/www/apps/phoenix/nuxt/middleware/02.permission-route-protection.global.ts";
import manifest_45route_45rule from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45auth_45route_45protection_45global,
  _01_45autoload_45global,
  _02_45permission_45route_45protection_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}