export const AUTH_ROUTE_TYPE_PLAN_GUEST = 'plan_guest';
export const AUTH_ROUTE_TYPE_MANAGE_GUEST = 'manage_guest';
export const AUTH_ROUTE_TYPE_AUTHENTICATED = 'authenticated';

export const AUTH_ROUTE_TYPE_GHOST = 'ghost';
export const AUTH_ROUTE_TYPE_NONE = 'none';

// manage authenticated page types
// export const AUTH_ROUTE_TYPE_MANAGE_AUTHENTICATED_ADMINISTER = 'manage_authenticated_administer';
// export const AUTH_ROUTE_TYPE_MANAGE_AUTHENTICATED_VIEW_REPORTS = 'manage_authenticated_view_reports';
// export const AUTH_ROUTE_TYPE_MANAGE_AUTHENTICATED_EDIT_STUDENTS = 'manage_authenticated_edit_students';
// export const AUTH_ROUTE_TYPE_MANAGE_AUTHENTICATED_VIEW_STUDENTS = 'manage_authenticated_view_students';
