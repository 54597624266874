import type { RuntimeConfig } from 'nuxt/schema';
import type { AnalyticsProvider } from '~analytics/types/analyticsProvider';

export default class MockProvider implements AnalyticsProvider {
  serviceName: string;
  subscribedEvents: string[];

  constructor(serviceName: string, config: RuntimeConfig, subscribedEvents: string[]) {
    this.serviceName = serviceName;
    this.subscribedEvents = subscribedEvents;
  }

  init() {
    // eslint-disable-next-line no-console
    console.log('Analytics mock provider initialised');
  }

  logMockCall(functionName: string, args: object) {
    // eslint-disable-next-line no-console
    console.log([this.serviceName, functionName, args]);
  }

  track(eventName: string, eventData?: object) {
    this.logMockCall(eventName, eventData || {});
  }

  identify(guid: string | undefined) {
    this.logMockCall('identify', { guid });
  }

  getSubscribedEvents() {
    return this.subscribedEvents;
  }
}
