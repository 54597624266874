import revive_payload_client_rrjmoahYtj from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ENqbqfl4b3 from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_euygSdA2et from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mlUiLFeTOh from "/var/www/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.5_sa_cf3115f5a0f7efa35d056e923ef7daca/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import _03_seo_fZau0DgIwP from "/var/www/apps/phoenix/nuxt/plugins/03.seo.ts";
import payload_client_uMvm5XAThg from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tYm5CGBrvV from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nzDXxJi3ck from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5qqTjAwm3d from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DM31AB20je from "/var/www/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/apps/phoenix/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_1vBlBfIFB6 from "/var/www/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@_25744f0de8aa990ec3d8e276406bfee7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m2yhMZAfji from "/var/www/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_@unhead+vue@1.11.18_vue@3.5.13_typescript@5.5.4___h3@1.14.0_mag_6adfcf9670d02bc775f759811b842925/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_2dVOfD0hf9 from "/var/www/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_@unhead+vue@1.11.18_vue@3.5.13_typescript@5.5.4___h3@1.14.0_mag_6adfcf9670d02bc775f759811b842925/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_W7PnD1gktT from "/var/www/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.5_9148cb33662fae38f4a5085d13e2a486/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_leQGI2qgov from "/var/www/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@22.10.5_9148cb33662fae38f4a5085d13e2a486/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_4yoUPp1Y4C from "/var/www/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_GCF0f0Vc4s from "/var/www/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_datadog_client_oBfMsBvh4D from "/var/www/apps/phoenix/nuxt/plugins/00.datadog.client.ts";
import _01_urql_b9cmdWz5Xy from "/var/www/apps/phoenix/nuxt/plugins/01.urql.ts";
import _02_loadConfig_RK9zG7A5iq from "/var/www/apps/phoenix/nuxt/plugins/02.loadConfig.ts";
import _04_auth_b3V54MNNLz from "/var/www/apps/phoenix/nuxt/plugins/04.auth.ts";
import _05_analytics_BKRvirLbPf from "/var/www/apps/phoenix/nuxt/plugins/05.analytics.ts";
import _06_appAssets_dOxy1bcLa6 from "/var/www/apps/phoenix/nuxt/plugins/06.appAssets.ts";
import _07_featureFlagsProvider_wvQWIS5tbq from "/var/www/apps/phoenix/nuxt/plugins/07.featureFlagsProvider.ts";
import gsap_client_YCLjR5XmPz from "/var/www/apps/phoenix/nuxt/plugins/gsap.client.ts";
export default [
  revive_payload_client_rrjmoahYtj,
  unhead_ENqbqfl4b3,
  router_euygSdA2et,
  _0_siteConfig_mlUiLFeTOh,
  _03_seo_fZau0DgIwP,
  payload_client_uMvm5XAThg,
  navigation_repaint_client_tYm5CGBrvV,
  check_outdated_build_client_nzDXxJi3ck,
  chunk_reload_client_5qqTjAwm3d,
  plugin_vue3_DM31AB20je,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1vBlBfIFB6,
  titles_m2yhMZAfji,
  defaultsWaitI18n_2dVOfD0hf9,
  siteConfig_W7PnD1gktT,
  inferSeoMetaPlugin_leQGI2qgov,
  switch_locale_path_ssr_4yoUPp1Y4C,
  i18n_GCF0f0Vc4s,
  _00_datadog_client_oBfMsBvh4D,
  _01_urql_b9cmdWz5Xy,
  _02_loadConfig_RK9zG7A5iq,
  _04_auth_b3V54MNNLz,
  _05_analytics_BKRvirLbPf,
  _06_appAssets_dOxy1bcLa6,
  _07_featureFlagsProvider_wvQWIS5tbq,
  gsap_client_YCLjR5XmPz
]