import type { RuntimeConfig } from 'nuxt/schema';
import type { AnalyticsProvider } from '~analytics/types/analyticsProvider';

interface PixelAnalytics {
  fbq: (operationType: string, eventName: string, eventData?: object) => void;
}
export default class MetaPixelProvider implements AnalyticsProvider {
  serviceName: string;
  subscribedEvents: string[];
  pixelAnalytics?: PixelAnalytics;

  constructor(serviceName: string, config: RuntimeConfig, subscribedEvents: string[]) {
    this.serviceName = serviceName;
    this.subscribedEvents = subscribedEvents;
  }
  async init() {
    this.pixelAnalytics = <PixelAnalytics>await useScriptMetaPixel();
  }

  track(eventName: string, eventData?: object) {
    this.pixelAnalytics?.fbq('track', eventName, eventData);
  }

  identify() {
    // noop atm
  }

  getSubscribedEvents() {
    return this.subscribedEvents;
  }
}
