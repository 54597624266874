import GoogleAnalyticsProvider from '~analytics/providers/GoogleAnalyticsProvider';
import MetaPixelProvider from '~analytics/providers/MetaPixelProvider';
import MixpanelProvider from '~analytics/providers/MixpanelProvider';
import MockProvider from '~analytics/providers/MockProvider';

export default defineNuxtPlugin({
  name: 'analytics-service',
  setup: () => {
    const analytics = useAnalytics();

    const config = useRuntimeConfig();

    const allEvents: string[] = Object.keys(EVENTS).map((eventKey) => EVENTS[<keyof typeof EVENTS>eventKey]);

    if (import.meta.client) {
      if (config.public.analyticsEnabled) {
        void analytics.registerProvider(new MixpanelProvider('mixpanel', config, allEvents));
        void analytics.registerProvider(new GoogleAnalyticsProvider('google-analytics', config, [EVENTS.ACCOUNT_REGISTER_COMPLETED]));
        void analytics.registerProvider(new MetaPixelProvider('meta-pixel', config, [EVENTS.ACCOUNT_REGISTER_COMPLETED]));
      } else {
        void analytics.registerProvider(new MockProvider('mixpanel', config, allEvents));
        void analytics.registerProvider(new MockProvider('google-analytics', config, [EVENTS.ACCOUNT_REGISTER_COMPLETED]));
        void analytics.registerProvider(new MockProvider('meta-pixel', config, [EVENTS.ACCOUNT_REGISTER_COMPLETED]));
      }
    }

    return { provide: { analytics } };
  },
});
