<script lang="ts" setup>
interface Props {
  variant?: "default" | "surface" | "glassy" | "primary";
  shape?: "default" | "chat";
  shadow?: "none" | "xs" | "sm" | "md" | "lg";
  padding?: "none" | "md" | "lg" | "xl";
  isButton?: boolean;
  customClasses?: string;
  overflow?: "visible" | "hidden";
  borderType?: "dashed" | "none" | "solid";
  backgroundColor?: "blue" | "none";
}

const props = withDefaults(defineProps<Props>(), {
  variant: "default",
  shape: "default",
  shadow: "md",
  isButton: false,
  customClasses: "",
  padding: "md",
  overflow: "visible",
  borderType: "none",
  backgroundColor: "none",
});

const cardType = computed<"button" | "div">(() =>
  props.isButton ? "button" : "div",
);

const variantClasses = computed<string>(() => {
  return twMerge(
    cva("", {
      variants: {
        variant: {
          default: "border border-grey-100 bg-white",
          surface: "bg-opacity-80 backdrop-blur-sm drop-shadow-lg bg-white",
          glassy: "bg-white/50 backdrop-blur-md rounded-xl shadow-lg",
          primary: "bg-primary-50/50",
        },
        backgroundColor: {
          blue: "bg-blue-50",
          none: "",
        },
        borderType: {
          dashed: "border-dashed border-2 border-grey-100",
          solid: "border border-grey-100",
          none: "",
        },
        overflow: {
          visible: "overflow-visible",
          hidden: "overflow-hidden",
        },
        padding: {
          none: "px-0 py-0",
          md: "py-4 p-2 md:py-6 md:px-4",
          lg: "p-6 md:p-8",
          xl: "px-8 py-6 md:px-[55px] md:py-[44px]",
        },
        shape: {
          default: "rounded-2xl",
          chat: "rounded-tl-none rounded-tr-3xl rounded-br-3xl rounded-bl-3xl",
        },
        shadow: {
          none: "",
          md: "shadow-md",
          lg: "shadow-lg",
          sm: "shadow-sm",
          xs: "shadow-xs",
        },
      },
    })({
      variant: props.variant,
      shape: props.shape,
      shadow: props.shadow,
      overflow: props.overflow,
      padding: props.padding,
      borderType: props.borderType,
      backgroundColor: props.backgroundColor,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <component :is="cardType" :class="variantClasses">
    <div class="w-full h-full">
      <slot />
    </div>
  </component>
</template>
