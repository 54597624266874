<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import type { ModalCloseMethod } from '~ui/types/modal';

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  customClasses?: string;
  hideCloseButton?: boolean;
  stopOutsideClose?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'lg',
  customClasses: '',
  hideCloseButton: false,
  stopOutsideClose: false,
});

const emit = defineEmits<{
  close: [howClosedModal: ModalCloseMethod];
}>();

const { elementRef: modalContent } = useFocusTrap();

onClickOutside(modalContent, () => {
  if (!props.stopOutsideClose) {
    emit('close', 'outside');
  }
});

const variantProps = computed(() => {
  return twMerge(
    cva('w-full rounded-2xl overflow-y-auto bg-white relative overflow-y-hidden', {
      variants: {
        size: {
          sm: 'max-w-sm',
          md: 'max-w-md',
          lg: 'max-w-lg',
          xl: 'max-w-xl',
          '2xl': 'max-w-2xl',
          '3xl': 'max-w-3xl',
          '4xl': 'max-w-4xl',
        },
      },
    })({
      size: props.size,
    }),
  );
});

const contentClasses = computed(() => {
  return twMerge(cva('w-full')({}), props.customClasses);
});
</script>

<template>
  <div
    aria-modal="true"
    class="fixed top-0 left-0 z-50 h-full w-full overflow-hidden md:py-10 bg-black bg-opacity-50 md:flex md:items-center md:justify-center"
    role="dialog"
  >
    <div ref="modalContent" :class="variantProps" class="p-0" @keydown.esc="emit('close', 'x')">
      <div class="overflow-x-hidden p-6 max-h-screen">
        <div class="flex justify-between items-start w-full text-left">
          <div>
            <slot name="heading" />
          </div>
        </div>
        <div :class="contentClasses" class="relative">
          <slot />
        </div>
        <UIButton
          v-if="!hideCloseButton"
          :hideText="true"
          class="absolute right-4 top-4"
          iconPath="cross"
          variant="no-style"
          @click="emit('close', 'x')"
          @keydown.esc="emit('close', 'x')"
        />
      </div>
    </div>
  </div>
</template>
