import { SiteType } from '~/types/analyticsProvider';

export const useAnalytics = () => {
  const nuxtApp = useNuxtApp();
  const { user, globalStudentRole, isGhostStudent } = useAuth();
  const configStore = useConfigStore();
  const { isManageDomain } = useDomainConfig();

  const isYear13User = computed(() => {
    if (!user.value) {
      return false;
    }

    if (!user.value.primaryEmail) {
      return false;
    }

    const lowercaseEmail = user.value.primaryEmail.toLowerCase();

    if (lowercaseEmail.endsWith('@year13.com.au') || lowercaseEmail.endsWith('@careertools.com.au')) {
      return true;
    }

    return false;
  });

  const baseEventData = () => {
    const siteType = isManageDomain ? SiteType.MANAGE : SiteType.PLAN;

    return {
      cluster: configStore.cluster?.name || 'unknown',
      pageName: nuxtApp._route.name,
      product: siteType,
      evaluatedFlags: JSON.stringify(useFeatureFlags().getEvaluatedFlags()),
      ...(user.value
        ? {
            userId: user.value?.id,
            classYear: siteType === SiteType.PLAN ? globalStudentRole.value?.classYear : null,
            isYear13User: isYear13User.value,
            isGhostUser: siteType === SiteType.PLAN ? isGhostStudent.value : false,
            isManageReferred: siteType === 'plan' ? !!useCrossSitesConfig().isManageReferred.value : undefined,
          }
        : {}),
    };
  };

  const analyticsServiceProvider = useAnalyticsServiceProvider(baseEventData);

  return {
    ...analyticsServiceProvider,
  };
};
